<template>
  <div class="bg-white-2">
    <img class="mb-2" :src="layout[0].banner[0]" width="100%" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4">
            <div class="card mb-4">
              <div v-if="layout[0].status === 'START' || loading_start">
                <h1 class="text-center mt-2">
                  <pulse-loader
                    :color="color"
                    :size="size"
                    v-if="lotto2 === null && loading_start"
                  ></pulse-loader>
                  <b v-else>{{ lotto1 }}</b>
                </h1>

                <p class="text-center">
                  {{ `ງວດປະຈຳວັນທີ່  ${formatDate(Date.now())}` }}
                </p>

                <div>
                  <table class="table text-center table-sm table-bordered">
                    <tbody>
                      <th>
                        <h4>ເລກ 5 ໂຕ</h4>
                      </th>
                      <th>
                        <pulse-loader
                          :color="color"
                          :size="size"
                          v-if="lotto1 === null && loading_start"
                        ></pulse-loader>
                        <h4 v-else>{{ lotto1 }}</h4>
                      </th>
                      <tr v-for="(n, i) in 4" :key="i">
                        <th>
                          <h4>{{ `ເລກ ${4 - i} ໂຕ` }}</h4>
                        </th>

                        <th>
                          <pulse-loader
                            :color="color"
                            :size="size"
                            v-if="lotto1 === null && loading_start"
                          ></pulse-loader>
                          <h4 v-else>{{ formatDigit(i, lotto1) }}</h4>
                        </th>
                      </tr>
                      <th>
                        <h4>ເລກ 2 ພິເສດ</h4>
                      </th>
                      <th>
                        <pulse-loader
                          :color="color"
                          :size="size"
                          v-if="lotto2 === null && loading_start"
                        ></pulse-loader>
                        <h4 v-else>{{ lotto2 }}</h4>
                      </th>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-else>
                <div v-if="checkReward()">
                  <h1 class="text-center mt-2">
                    <b>{{ formatShowNo(lotto[0].rewards, 1) }}</b>
                  </h1>

                  <p class="text-center">
                    {{ `ງວດປະຈຳວັນທີ່ ${formatDate(lotto[0].date)}` }}
                  </p>

                  <div>
                    <table class="table text-center table-sm table-bordered">
                      <tbody>
                        <th>
                          <h4>ເລກ 5 ໂຕ</h4>
                        </th>
                        <th>
                          <h4>{{ formatShowNo(lotto[0].rewards, 1) }}</h4>
                        </th>
                        <tr v-for="(n, i) in 4" :key="i">
                          <th>
                            <h4>{{ `ເລກ ${4 - i} ໂຕ` }}</h4>
                          </th>
                          <th>
                            <h4>
                              {{
                                formatDigit(
                                  i,
                                  formatShowNo(lotto[0].rewards, 1)).toString()
                                
                              }}
                            </h4>
                          </th>
                        </tr>
                        <th>
                          <h4>ເລກ 2 ພິເສດ</h4>
                        </th>
                        <th>
                          <h4>{{ formatShowNo(lotto[0].rewards, 2).toString() }}</h4>
                        </th>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <h4 class="text-box">ຜົນການອອກເລກລາງວັນ</h4>
            <div class="card mb-4">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th class="text-center">ງວດວັນທີ່</th>
                    <th v-for="(n, i) in 5" :key="i" class="text-center">
                      {{ `ເລກ ${5 - i} ໂຕ` }}
                    </th>
                    <th class="text-center">ເລກ 2 ພິເສດ</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    class="text-center"
                    v-for="(data, index) in lotto"
                    :key="index"
                  >
                    <td>
                      {{ formatDate(data.date) }}
                    </td>
                    <td>
                      {{ formatShowNo(data.rewards, 1) }}
                    </td>
                    <td v-for="(n, i) in 4" :key="i">
                      {{
                        formatDigit(i, formatShowNo(data.rewards, 1).toString())
                      }}
                    </td>
                    <td>
                      {{ formatShowNo(data.rewards, 2) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-for="(data, index) in ads_box" :key="index">
          <img class="mb-2" :src="data.image" width="100%" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "bootstrap/dist/css/bootstrap.min.css";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  components: { PulseLoader },
  props: {
    lotto: Array,
    layout: Array,
  },
  computed: {},

  created() {
    for (var i = 0; i < this.layout[0].ads.length; i++) {
      let swap = true;
      if (i >= this.layout[0].ads.length / 2) {
        swap = false;
      }
      this.ads_box.push({ image: this.layout[0].ads[i], swap: swap });
    }

    this.sockets.subscribe(`start_2`, () => {
      this.loading_start = true;
      this.loading_reward = false;
    });

    this.sockets.subscribe(`reward_2`, (data) => {
      // this.loading_reward = true;
      if (data.id === 1) {
          this.lotto1 = data.lotto;
        }
        if (data.id === 2) {
          this.lotto2 = data.lotto;
        }
    });

    this.sockets.subscribe(`lotto_2`, (data) => {
        if (data.id === 1) {
          this.lotto1 = data.lotto;
        }
        if (data.id === 2) {
          this.lotto2 = data.lotto;
        }
    });
  },
  data() {
    return {
      loading_start: false,
      loading_reward: false,
      lotto1: null,
      lotto2: null,
      month: [
        "ມັງກອນ",
        "ກຸມພາ",
        "ມີນາ",
        "ເມສາ",
        "ພຶດສະພາ",
        "ມີຖຸນາ",
        "ກໍລະກົດ",
        "ສິງຫາ",
        "ກັນຍາ",
        "ຕຸລາ",
        "ພະຈິກ",
        "ທັນວາ",
      ],
      ads_box: [],
      color: "#000",
      size: "6px",
    };
  },
  methods: {
    formatDate(val) {
      let date = new Date(val);
      date = new Date(date.getTime() - 7 * 60 * 60 * 1000).toString();
      return (
        date.split(" ")[2] +
        " " +
        this.month[new Date(val).getMonth()] +
        " " +
        date.split(" ")[3]
      );
    },
    formatDigit(split, data) {
      var temp = "";

      var temp_data = data;

      for (var i = 0; i < 5; i++) {
        if (i > split) temp += temp_data.split("")[i];
      }
      return temp;
    },
    formatShowNo(data, showNo) {
      var show = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i].showNo === showNo) {
          show =
            data[i].value.toString().length === 4 ||
            data[i].value.toString().length === 1
              ? "0" + data[i].value
              : data[i].value;
        }
      }
      return show;
    },
    checkReward() {
      try {
        return this.lotto[0].rewards[1].value;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
<style>
</style>
